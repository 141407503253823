<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Data Capture" subtitle="View the data captured on non-registration pages.">

		<app-content-head-actions-panel v-if="collection.length" text="Export" icon="export" component="manager/common/panel/Export" :data="exportData" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.pages" text="Page" tooltip="Filter by page" :options="filterPageOptions" />
		<app-content-filters-date v-model="filter.from" tooltip="Filter from date" text="From" :max="filter.to" />
		<app-content-filters-date v-model="filter.to" tooltip="Filter to date" text="To" :min="filter.from" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no data capture found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column text="Email" />
				<app-table-column text="Page" />
				<app-table-column text="Date" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.email" />
					<app-table-cell-text :text="item.page.name" />
					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				pages: []
			},
			filter: {
				pages: [],
				from: 0,
				to: 0
			},
			layout: 'auto 200px 200px 200px',
			endpoint: 'convention/registration/captures',
			live: 'convention/registration/capture'
		}

	},

	computed: {

		filterPageOptions: function() {

			var sources = []

			this.$_.each(this.references.pages, function(source) {

				sources.push({
					value: source.id,
					text: source.name
				})

			})

			return sources

		},

		exportData: function() {

			return {
				columns: {
					firstname: 'First name',
					lastname: 'Last name',
					email: 'Email',
					page: 'Page',
					date: 'Date',
					data: 'Custom Data'
				},
				requiredColumns: [],
				valueMapping: {},
				params: this.getParams(),
				index: this.selected,
				endpoint: this.endpoint
			}

		}

	}

}

</script>

<style scoped>

</style>